// import AOS from 'aos';
import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import "magnific-popup";


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init();

  // header
  $('.menu-link').click(function(e) {
    $(this).parent('.dropdown').toggleClass('active');
  });
  $('.dropdown-link.dropdown').click(function(e) {
      e.preventDefault();
      $(this).toggleClass('active');
  });
  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('#headerMenu').toggleClass('active');
    $('#header').toggleClass('active');
    $('.header-overlay').toggleClass('active');
  });

  var footer_type = $('.wrap').data('footer-type');
  if (footer_type == 'second') {
    $('.footer').addClass('second-bg');
  }

  // tab
  $(function(){
    var tab = $('.tabs-group .tabs-btn a.tab');
    $(tab.eq(0).addClass('active').attr('href')).addClass('active').siblings('.tab-content').hide();

    tab.click(function(){
        event.preventDefault();
        $($(this).attr('href')).addClass('active').slideDown().siblings('.tab-content').hide();
        $(this).addClass('active').siblings('.active').removeClass('active');
    });
  });

  // SDGS 輪播
  var enSdgs = new Swiper('#enSdgs', {
    // autoplay: {
    //   delay: 5000,
    // },
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    breakpoints: {
      376: {
        slidesPerView: 2,
      },
      801: {
        slidesPerView: 4,
      },
      1281: {
        slidesPerView: 6,
      },
    },
  });

  // 首頁 PSR 輪播
  var indexPSR = new Swiper('#indexPsr', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    breakpoints: {
      376: {
        slidesPerView: 1,
      },
      800: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  });

  // 首頁 CSR 輪播
  var indexCSR = new Swiper('#indexCsr', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    breakpoints: {
      376: {
        slidesPerView: 1,
      },
      800: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  });

  // 首頁 GSR 輪播
  var indexGSR = new Swiper('#indexGsr', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    breakpoints: {
      376: {
        slidesPerView: 1,
      },
      800: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  });

  // 一般popup
  $("[data-swal]").on('click', function(){
    event.preventDefault();
    let id = $(this).data('swal');
    let data = $("[data-id=" + id + "]").html();
    Swal_lg.fire({
      html: '<div class="swal-details">' + data+ '</div>',
    });
  });
  // 各地分會popup
  $("[data-swalbg]").on('click', function(){
    event.preventDefault();
    let id = $(this).data('swalbg');
    let data = $("[data-id=" + id + "]").html();
    Swal_content.fire({
      html: '<div class="swal-details">' + data+ '</div>',
      customClass: {
        container: 'association-popup mobile-full',
      }
    });
  });

  // en btn more
  $('.btn-more.open').click(function(event){
    $(this).toggleClass('active');
    $(this).parent().siblings('.expend-content').toggleClass('active')
    if($(this).hasClass('active')) {
      $(this).find('span').html('Close')
    }else {
      $(this).find('span').html('More')
    }
  });

  // video
  $('.popup-video').magnificPopup({
    type: 'iframe'
  });

  // 壯世代先鋒
  var activeAccordion = $('.accordion-item .accordion-title');
  $(activeAccordion).click(function(){
    $(this).toggleClass('active').siblings('.accordion-content').slideToggle(200);
    $(this).parent('.accordion-item').siblings('.accordion-item').find('.accordion-content').slideUp(200).siblings('.accordion-title').removeClass('active');
  });
});
